@import '../baseStyle/variables.scss';

@font-face {
  font-family: SohneBreit-Kraftig;
  src: url('../fonts/SohneBreit-Kraftig.woff2') format('woff2'), url('../fonts/SohneBreit-Kraftig.woff') format('woff'), url('../fonts/SohneBreit-Kraftig.ttf') format('truetype');
}

// Reset

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  // Temporary removed due to conflict with Safari, inputs are not writable
  // user-select: none;
}

html {
  font-family: var(--font-family-base);
  min-height: 100vh;
  color: var(--color-text-base);
  background: var(--background-base);
}

body {
  overscroll-behavior: none;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

textarea {
  background: var(--color-secondary);
  font-family: var(--font-family-base);
  color: var(--color-text-base);
  font-size: var(--font-size-small);
  outline: none;
  border-radius: var(--border-radius-input-text);
  border: 2px solid #a4a4a4;
  padding: 16px;
  resize: none;
  margin: 8px 0;
  width: 100%;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0 0 0 / 0%);
}

textarea:focus {
  border: 2px solid #a4a4a4;
}

// TODO: Refactor implementation acroas app

.loading-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

// Typography

h1,
.subtitle-large {
  font-family: SohneBreit-Kraftig, sans-serif;
  font-size: var(--font-size-xlarge);
  line-height: var(--font-size-xlarge);
  color: var(--color-text-h1);
}

h2 {
  font-family: SohneBreit-Kraftig, sans-serif;
}

h2,
h3 {
  font-size: var(--font-size-large);
}

h4,
h5,
h6 {
  font-size: var(--font-size-base);
  color: var(--color-text-h1);
}

p,
li,
a {
  font-size: var(--font-size-base);
}

blockquote {
  quotes: '“' '”';
}

blockquote::before {
  content: open-quote;
}

blockquote::after {
  content: close-quote;
}

.error-message-small {
  font-size: var(--font-size-xsmall);
  margin-left: var(--space-xsmall);
}

.centred-text {
  text-align: center;
}

.link {
  font-weight: 700;
}

.copy-text {
  font-size: var(--font-size-xsmall);
  text-align: center;
  text-transform: uppercase;
}

// Layout text colour classes

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-text-base {
  color: var(--color-text-base);
}

.color-error {
  color: var(--color-error);
}

// Layout background colour classes

.background-base {
  background: var(--background-base);
}

.yellow-background {
  position: fixed;
  background: var(--color-secondary);
  z-index: -1;
}

// Layout margin classes

.content-margin-small {
  margin: var(--space-small) 0;
}

.content-margin-base {
  margin: var(--space-base) 0;
}

.content-margin-large {
  margin: var(--space-large) 0;
}

.content-margin-xlarge {
  margin: var(--space-xlarge) 0;
}

.padding-layout-horisontal {
  max-width: var(--desktop-content-width);
  margin: 0 auto;
  padding: 0 var(--space-large);
}

.padding-layout-base {
  padding: var(--space-base);
}

.title-group {
  position: fixed;
  display: flex;
  align-items: center;
  height: 96px;
  padding: 0 var(--space-large);

  // z-index: 1009;

  .title {
    margin-left: var(--space-large);
  }
}

.title-group.white {
  backdrop-filter: blur(var(--blur-overlay));
  background: var(--color-secondary);
}

.title-group.yellow {
  background: #fef9efcc;
}

.title-group-content {
  padding-top: 96px;
}

@media only screen and (max-width: $max-mobile-breakpoint) {

  .yellow-background {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
  }

  .title-group {
    width: 100vw;
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .yellow-background {
    top: var(--header-height);
    left: 240px;
    height: calc(100vh - var(--header-height));
    width: calc(100vw - 240px);
  }

  .title-group {
    width: calc(100vw - 240px);
  }
}

// Cursor

.btn-primary-small,
input[type='radio'],
input[type='checkbox'],
.edit-icon,
.calendar-modal-container,
.month-year-picker-input,
.data-picker,
.job-entry-title,
.trash-icon,
.link,
.info-icon {
  cursor: pointer;
}

// Input and buttons styles

button,
input,
label,
::placeholder {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  color: #a4a4a4;
}

::placeholder {
  color: #9c9c9c;
}

label {
  color: var(--color-text-base);
}

input[type='text'],
input[type='email'],
input[type='password'] {

  &.error {
    border-bottom: var(--border-line) solid var(--color-error);
    color: var(--color-error);

    &::placeholder {
      color: var(--color-error);
    }
  }

  display: block;
  width: 100%;
  height: 48px;
  font-family: var(--font-family-base);
  color: #a4a4a4;
  background: var(--color-secondary);
  border: none;
  border-bottom: 2px solid #a4a4a4;
  margin: var(--space-base) 0;
}

input[type='radio'],
input[type='checkbox']:not(.tag-cb) {
  width: 32px;
  height: 32px;
  border: 4px solid #ddd;
  background: #ddd;
  margin: 0;
  transition: box-shadow 0.5s;
  appearance: none;
}

input[type='radio'] {
  border-radius: 100%;
}

input[type='checkbox']:not(.tag-cb) {
  border-radius: 8px;
  border: 6px solid #ddd;
}

input[type='radio']:checked,
input[type='checkbox']:not(.tag-cb):checked {
  box-shadow: inset 0 0 0 16px var(--color-primary);
}

.checked-icon {
  position: absolute;
  pointer-events: none;
  width: 20px;
  height: 20px;
  transition: 0.5s;
  left: var(--space-xsmall);
  top: var(--space-small);
  transform: rotate(-45deg) translate(10px, -8px);
  opacity: 0;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -2px;
    width: 12px;
    height: 2px;
    background: var(--color-secondary);
    transition: 0.5s;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: -2px;
    width: 2px;
    height: 4px;
    background: var(--color-secondary);
    transition: 0.5s;
  }
}

input:checked~span.checked-icon {
  opacity: 1;
}

input#profile-avatar-input {
  visibility: hidden;
}

.centred-content,
form.logged-out-form {
  max-width: 400px;
  margin: var(--space-large) auto;
}

.checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  label {
    margin-left: var(--space-small);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background 5000s ease-in-out 0s;
  -webkit-text-fill-color: #a4a4a4;
}

button:focus,
button:focus-visible,
input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='text']:focus-visible,
input[type='email']:focus-visible,
input[type='password']:focus-visible {
  outline: none;
  border: 2px solid var(--color-text-base);
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='text']:focus-visible,
input[type='email']:focus-visible,
input[type='password']:focus-visible {
  border: none;
  border-bottom: 2px solid #a4a4a4;
}

// Button styles

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 100%;
  height: 48px;
  padding: var(--space-base);
  border: none;
  border-radius: var(--border-radius-button);
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  // TODO: Refactor Google auth button to fit brand guidelines!!

  svg {
    margin-right: var(--space-small);
  }
}

.btn:hover,
.btn:focus,
.btn:active {
  border: none;
}

.btn-primary-small {
  height: 30px;
  font-size: var(--font-size-xsmall);
  text-align: center;
  border-radius: var(--border-radius-button);
  padding: var(--space-xsmall);
  transition: background 0.5s ease;
  flex-grow: 1; // TODO: <- Should this be part of buttons global styles?
}

.btn-primary-small-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-primary,
.btn-primary-small {
  color: var(--color-secondary);
  background: var(--color-primary);
}

.btn-secondary {
  color: var(--color-primary);
  background: var(--color-secondary);
}

.btn-text {
  color: var(--color-primary);
  background: transparent;
}

.btn-primary,
.btn-secondary,
.btn-text {

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-secondary:focus,
.btn-secondary:hover {
  background: #fff;
}

.btn-secondary:active {
  background: #f5efe2;
}

.btn-primary:focus,
.btn-primary:hover,
.btn-primary-small:hover {
  background: #ff7280;
}

.btn-primary:active,
.btn-primary-small:active {
  background: #d73242;
}

.btn-text:focus,
.btn-text:hover {
  color: #ff7280;
}

.btn-text:active {
  color: #d73242;
}

.btn-primary-small-disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-inline-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .btn {
    width: 40%;
    height: 45px;
    padding: var(--space-small);
  }

  .btn:not(:first-child) {
    margin-left: var(--space-small);
  }
}

.btn-block-wrapper .btn:not(:first-child) {
  margin-top: var(--space-base);
}

.btn-result {
  background: var(--color-primary);
  padding: var(--space-small) var(--space-base);
}

.btn-accent-outlined {
  border: 2px solid var(--color-secondary);
  padding: var(--space-xsmall) var(--space-base);
}

.btn-result,
.btn-accent-outlined {
  border-radius: var(--border-radius-button);
  color: var(--color-secondary);
}

.btn-result:hover {
  background: #ff7280;
}

.btn-ggl {
  display: flex;
  align-items: center;
  height: 48px;
  font-family: Roboto, sans-serif;
  color: rgba(0 0 0 / 54%);
  border: 2px solid #969696;
  border-radius: var(--border-radius-container);
  background: #fff;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span.ggl-logo {
    width: 52px;
  }

  span.ggl-text {
    width: 100%;
    color: #969696;
    font-weight: 700;
    padding: 16px;
  }
}

.settings-card-items button:not(:disabled) {
  cursor: pointer;
}

.settings-card-items button:disabled {
  cursor: not-allowed;
}

select {
  appearance: none;
  outline: none;
  width: 100%;
  font-family: var(--font-family-base);
  color: #a4a4a4;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #a4a4a4;
  padding: var(--space-base);
  padding-left: 0;
  background: var(--color-secondary);
  cursor: pointer;
}

[data-badge] {
  position: relative;

  &::after {
    position: absolute;
    right: -28px;
    top: -10px;
    min-width: 20px;
    height: 20px;
    font-size: 12px;
    color: var(--color-secondary);
    text-align: center;
    background: var(--color-badge);
    border-radius: 20px;
    padding: 2px;
    content: attr(data-badge);
  }
}
