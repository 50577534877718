@import '../../../baseStyle/placeholders.scss';
@import '../../../baseStyle/variables.scss';

.signup-step {
  @extend %flex-column-layout;

  width: 100vw;
}

.signup-step-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.signup-step-flex-grow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.signup-step-card {
  @extend %signup-steps-card;

  flex-grow: 1;
}

.data-picker-label {
  padding-bottom: var(--space-xsmall);
}

.signup-header-container {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1008;
}

.signup-step-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;

  .btn {
    width: 110px;
  }
}

.signup-first-step-header {
  justify-content: flex-end;
}

.birthday-picker {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: var(--space-base);
  text-align: center;
}

.avatar-upload-container {
  display: flex;
  align-items: center;
  justify-items: center;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.avatar-uploaded {
  border: 2px solid var(--color-primary);
}

.avatar-label {
  margin-left: var(--space-base);
}

.backdrop-filter {
  @extend %backdrop-filter;
}

@media only screen and (max-width: $max-mobile-small-breakpoint) {

  .signup-step-header,
  .signup-step-content {
    width: 100%;
    padding: 0 var(--space-large);
  }
}

@media only screen and (min-width: $min-mobile-big-breakpoint) {

  .signup-step-header,
  .signup-step-content {
    width: 448px;
    padding: 0;
    margin: 0 auto;
  }
}
