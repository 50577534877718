@import '../../../../baseStyle/variables.scss';
@import '../../../../baseStyle/placeholders.scss';

.complete-profile-list-container {
  width: 100%;
}

.item-container {
  display: flex;
  align-items: baseline;
  max-width: 600px;
  margin: 0 auto;
}

.item-left-wrapper {
  display: inline-flex;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  border: var(--border-line-bold) solid #ccc;
  border-radius: 50%;

  img {
    padding: var(--space-xsmall);
  }
}

.item-right-wrapper {
  background: var(--color-secondary-darken);
  border: 1.5px solid #ccc;
  border-radius: var(--border-radius-container);
  margin: var(--space-large);
  margin-right: 0;
  padding: var(--space-large);
  padding-bottom: 0;
  flex-grow: 1;
}

.item-right-title {
  display: flex;
  justify-content: space-between;

  h2 {
    color: #303030;
  }

  img {
    margin-left: var(--space-small);
    border-radius: var(--border-radius-container);
    cursor: pointer;
  }
}

.todo h2 {
  cursor: pointer;
}

.completed h2 {
  color: #aaa;
}

@media only screen and (max-width: $max-mobile-small-breakpoint) {

  .item-right-title {

    h2 {
      font-size: var(--font-size-base);
    }

    img {
      width: 96px;
    }
  }
}

@media only screen and (min-width: $min-mobile-big-breakpoint) {

  .item-right-title {

    h2 {
      margin-right: var(--space-large);
      font-size: var(--font-size-large);
    }

    img {
      width: 116px;
    }
  }
}
