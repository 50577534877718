.calendar-modal-overlay { // Should be revisited in global styles when modals defined globaly!!
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1009; // WIP
}

.calendar-modal-overlay.blure-overlay {
  backdrop-filter: blur(8px);
  background: rgb(0 0 0 / 40%);
}

.calendar-modal-container {
  width: calc(100% - 2 * var(--space-large));
  max-width: 400px;
  border-radius: 16px;
  overflow: hidden;
}

.calendar-modal-bottom-container {
  height: 380px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal-entry-container {
  text-align: center;
  color: var(--color-text-base);

  .entry-list-item {
    padding: var(--space-base);
  }

  .entry-list-item:nth-child(odd) {
    background: #fff;
  }

  .entry-list-item:nth-child(even) {
    background: #f5f5f5;
  }
}

.calendar-modal-top-container {
  position: sticky;
  background: var(--color-primary-lighten-1);
  box-shadow: 0 0 4px var(--color-primary);
  border-radius: 16px 16px 0 0;

  .calendar-date-container {
    display: grid;
    gap: 1px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .calendar-date-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-base) var(--space-xlarge);
    background: var(--color-primary);

    p {
      color: var(--color-secondary);
    }

    img {
      padding-left: var(--space-xsmall);
      transition: transform 0.1s linear;
    }
  }

  .active {
    background: var(--color-primary-lighten-1);

    img {
      transform: rotate(90deg) translate(-16.5%, -33.33%);
      transition: transform 0.1s linear;
    }
  }
}
