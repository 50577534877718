.survey-option {
  display: flex;
  align-items: center;
  padding: var(--space-base);
  border-bottom: 2px solid var(--color-secondary-darken);

  input[type='radio'] {
    min-width: 32px;
    min-height: 32px;
  }
}

.survey-option label {
  color: var(--color-text-base);
  margin-left: var(--space-base);
}
