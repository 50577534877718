.input-group {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.input-group-item-large {
  width: 80%;
}

.input-group-item-small {
  width: 20%;
}
