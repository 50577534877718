.month-year-picker-container {
  position: relative;

  input.not-picked {
    padding-left: 40px;
  }

  .calendar-icon {
    position: absolute;
    top: var(--space-base);
    left: var(--space-large);
  }
}

.month-year-picker-input {
  padding-left: var(--space-base);
}
