.result-meter-wrapper {
  position: relative;
  width: 300px;
  z-index: -1;
}

.pointer-wrapper {
  position: absolute;
  top: -15px;
  left: 87px;
  height: 420px;
  transform-origin: 50% 40%;
  transform-box: fill-box;
  transform-style: preserve-3d;
  animation: meter-animation 2s ease;
}

@keyframes meter-animation {

  0% { transform: rotate(-90deg); }
}
