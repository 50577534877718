@import '../../../baseStyle/variables.scss';
@import '../../../baseStyle/placeholders.scss';

.signup-auth-wrapper {
  display: flex;
  flex-direction: column;
  background: var(--color-secondary);
  min-height: 100vh;
}

.signup-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.signup-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 280px;
  min-height: 600px;
  margin: 0 auto;
  margin-top: var(--space-xlarge);
}

.signup-tucan {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: none;
  }
}

.signup-back-btn {
  position: fixed;
  top: var(--space-xlarge);
  left: var(--space-large);
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .signup-auth-wrapper {
    flex-direction: row;
  }

  .signup-tucan {
    flex: 1;
    background: var(--color-secondary-darken);

    img {
      display: block;
      position: fixed;
      left: 0;
      width: 33.33%;
    }
  }

  .signup-back-btn {
    left: calc(50% + var(--space-xlarge));
  }
}
