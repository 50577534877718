.share-results-title {
  color: var(--color-text-base) !important;
}

.share-results-text {
  text-align: left !important;
}

.input-read-only {
  background-color: var(--color-secondary-darken) !important;
  border: none;
  color: var(--color-text-base);
  padding: 8px 40px 8px 8px;
  font-size: 14px;
  width: 100%;
}

.share-results-input {
  position: relative;
}

.share-results-icon {
  position: absolute;
  right: 8px;
  top: 6px;
  cursor: pointer;
}
