@import '../../baseStyle/variables.scss';
@import '../../baseStyle/placeholders.scss';

.auth-wrapper,
.auth-wrapper-primary {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-secondary);
}

.auth-wrapper-primary {
  background: var(--color-primary);
}

.splash-wrapper {
  display: flex;
  flex: 1;
}

.splash-container {
  max-width: 280px;
  margin: 0 auto;
  margin-top: 240px;
}

.splash-tucan {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    left: 0;
    top: 64px;
    width: 280px;
  }
}

.splash-title {
  line-height: 32px;
}

.privacy-policy {
  position: absolute;
  width: 100vw;
  bottom: env(safe-area-inset-bottom);
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .auth-wrapper,
  .auth-wrapper-primary {
    flex-direction: row;
  }

  .splash-container {
    margin-top: 33.33%;
  }

  .splash-tucan {
    flex: 1;
    background: var(--color-secondary-darken);

    img {
      width: 33.33%;
      top: unset;
    }
  }

  .privacy-policy {
    width: 50vw;
    left: 50%;
  }
}
