@import '../../baseStyle/variables.scss';
@import '../../baseStyle/placeholders.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  background: var(--background-overlay);
  padding: 0 var(--space-large);
  z-index: 1009;
}

.modal-container {
  display: flex;
  flex-direction: column;
  max-height: 468px;
  overflow-y: scroll;
  border-radius: var(--border-radius-modal);
  padding: var(--space-large);
  color: var(--color-text-base);
  background: var(--color-secondary);

  .modal-header {
    display: flex;
    justify-content: flex-end;

    svg {
      width: 18px;
      height: 18px;
      margin: var(--space-base);
      cursor: pointer;
    }
  }

  h2 { // WIP
    color: var(--color-primary);
    text-align: center;
    padding-bottom: var(--space-large);
  }

  p {
    font-size: var(--font-size-small);
    padding-bottom: var(--space-large);
    text-align: left;
  }
}

@media only screen and (max-width: $max-mobile-small-breakpoint) {

  .modal-container {
    width: 100%;
  }
}

@media only screen and (min-width: $min-mobile-big-breakpoint) {

  .modal-container {
    width: 400px;
  }
}
