.open-graph-item {
  border-radius: 16px;
  padding: 0 var(--space-base);
}

.open-graph-image-container {
  max-height: 245px;
  max-width: 400px;
  position: relative;

  img {
    border-radius: 16px;
    max-height: inherit;
    object-fit: fill;
    width: 100%;
  }
}

.open-graph-item-title {
  overflow: hidden;
  white-space: nowrap;
}

.open-graph-image-text-container {
  backdrop-filter: blur(var(--blur-overlay));
  background: rgb(255 255 255 / 70%);
  border-radius: 0 0 16px 16px;
  position: absolute;
  top: 70%;
  height: 30%;
  width: 100%;
}

.open-graph-background-container {
  background: var(--background-gray);
  backdrop-filter: blur(var(--blur-overlay));
  border-radius: 16px;
  max-width: 400px;
}
