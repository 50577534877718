.complete-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-secondary);
  padding: var(--space-large);
  z-index: 1009;

  .screen-icon {
    display: block;
    margin: 0 auto;
    width: 68px;
  }
}
