.job-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-secondary);
  border-radius: var(--border-radius-container);
  box-shadow: var(--shadow-raise);
  margin: var(--space-large) 0;

  @media screen and (min-width: 460px) {
    max-width: 420px;
    min-height: 122px;
    margin: var(--space-xlarge) auto;
  }
}

.job-item-image-wrapper {
  overflow: hidden;
  border-radius: var(--border-radius-modal) 0 0 var(--border-radius-modal);

  img {
    max-height: 120px;
    max-width: 120px;
  }

  .company-logo-placeholder {
    width: 120px;
    height: 110px;
  }
}

.job-item-content-container {
  width: 70%;
  color: #1b1b1b;
  padding: var(--space-large) var(--space-small);

  h3 {
    font-size: var(--font-size-xsmall);
  }
}

.job-item-description {
  font-size: 3vw;

  @media screen and (min-width: 480px) {
    font-size: var(--font-size-small);
  }
}
