.settings-card-container {
  border-radius: 20px;
  background: rgb(109 0 194 / 30%);

  h3 {
    font-weight: 400;
    padding: var(--space-large);
    background: #b8dee3;
    border-radius: var(--border-radius-container) var(--border-radius-container) 0 0;
  }

  .settings-card-items {
    display: flex;
    flex-direction: column;
    background: #fffcf6;
    border-radius: 0 0 var(--border-radius-container) var(--border-radius-container);

    button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      text-align: start;
      color: var(--color-text-base);
      padding: var(--space-base) var(--space-large);

      &:focus {
        border: none !important;
        box-shadow: none;
      }

      .label {
        margin-left: var(--space-base);
      }

      .label.dangerous {
        color: var(--color-error);
      }

      .option-item {
        margin-left: auto;
      }
    }

    button:disabled {
      color: #a4a4a4;
      -webkit-tap-highlight-color: transparent;

      span.disabled {
        font-size: 8px;
        width: 40px;
        margin-left: auto;
        text-align: center;
      }
    }
  }
}
