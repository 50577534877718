@import '../../../baseStyle/variables.scss';

.company-entry-container {
  background: var(--color-secondary);
  border-radius: var(--border-radius-container);
  min-width: 100%;
  margin-top: var(--space-xlarge);

  ul {
    padding-bottom: var(--space-large);
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0  0 var(--border-radius-container) var(--border-radius-container);
  }

  h4 {
    background: var(--color-secondary-accent);
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
    border-radius: var(--border-radius-container) var(--border-radius-container) 0 0;
    padding: var(--space-base);
  }

  .job-entry-container {
    position: relative;

    &::after {
      content: '';
      border-radius: 100%;
      background: var(--color-text-base);
      height: 7px;
      width: 7px;
      position: absolute;
      left: 8px;
      top: 20px;
    }

    .entry-line {
      background: var(--color-text-base);
      height: 60px;
      width: 1px;
      position: absolute;
      left: 11px;
      top: 22px;
    }
  }

  .job-entry-title-wrapper {
    display: flex;
    align-items: center;

    .job-entry-title {
      position: relative;
      display: block;
      flex-grow: 1;
      padding: 0 var(--space-small);
      background: #f5efe2;
      border-radius: var(--border-radius-container);
      margin: var(--space-base);
      margin-left: var(--space-xlarge);
      white-space: nowrap;
      overflow: hidden;
      text-transform: capitalize;
    }
  }

  .overflow-shadow {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    right: 0;
    background: linear-gradient(to right, #f5efe200, #f5efe2ff);
  }

  .job-entry-content-container {
    display: flex;
    align-items: center;
    color: #696969;
    margin-left: var(--space-xlarge);
    line-height: 1px;

    p {
      font-size: var(--font-size-base);
    }

    .location-job-wrapper {
      text-transform: uppercase;
    }
  }
}

.jobs-container-profile {

  .company-entry-container {
    margin: var(--space-xlarge) 0;
  }
}

.trash-icon {
  width: 24px;
  height: 24px;
  padding: 0 var(--space-xsmall);
  margin-right: var(--space-small);
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius-container);
}
