@import '../../baseStyle/variables.scss';

.content-wrapper {
  display: flex;
}

.content {
  min-height: 100vh;
  margin: 0 auto;
  background: var(--color-secondary);
}

.tabs-nav {
  position: fixed;
  display: flex;
  align-content: center;
}

.tab-link-group {
  display: flex;
  align-items: center;
  cursor: pointer;

  .tab-text.active {
    color: var(--color-primary);
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Bottom nav bar on mobile
// If new UI adopted for modal refactor, use it in new modal component

.more-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(8px);
  background: var(--background-overlay);
  z-index: 1009;
}

.more-close-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--space-large);
}

.more-content {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  // height: auto;
  background: var(--color-secondary);
  padding: var(--space-large);
}

.more-feedback-group {
  display: flex;
  align-items: flex-start;
  padding-top: var(--space-base);
  padding-bottom: var(--space-base);
  border-bottom: 2px solid var(--color-secondary-darken);

  img {
    margin-right: var(--space-large);
  }
}

.more-link-group {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.more-link-text {
  margin-left: var(--space-small);
  color: var(--color-text-base);
}

.more-link-text.active {
  color: var(--color-primary);
}

// Bottom nav bar on mobile end

.feedback-banner {
  position: relative;
  width: 100%;
  background: var(--color-secondary-accent);
  padding: var(--space-small) var(--space-large);
  cursor: pointer;

  .close-icon {
    position: absolute;
    top: 8px;
    right: 12px;
  }

  .btn {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: $max-mobile-breakpoint) {

  .full-height-content {
    min-height: 100vh;
  }

  .content {
    width: 100%;
  }

  .padding-layout-vertical {
    padding-top: 0;
    padding-bottom: 96px;
  }

  .tabs-nav {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height: var(--bottom-nav-height-mobile);
    margin: 0;
    bottom: 0;
    background: var(--color-secondary-darken);
    backdrop-filter: blur(var(--blur-overlay));
    z-index: 1009;

    .logo-desktop {
      display: none;
    }
  }

  .logo-mobile {
    display: block;
    width: 120px;
  }

  .tab-link-group {
    flex-direction: column;

    .tab-text {
      font-size: 8px;
      text-align: center;
      color: var(--color-text-base);
      margin-top: 6px;
    }
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .content {
    top: var(--header-height);
    bottom: 0;
    overflow-y: scroll;
    width: calc(100% - 240px);
    margin-left: 240px;
  }

  .padding-layout-vertical {
    padding-top: calc(var(--space-xlarge) * 2);
    padding-bottom: 0;
  }

  .tabs-nav {
    flex-direction: column;
    min-width: 240px;
    height: 100vh;
    background: var(--color-secondary-darken);
    padding: var(--space-base) var(--space-xlarge);
    z-index: unset;

    .logo-desktop {
      display: block;
      width: 148px;
      margin-top: var(--space-base);
      margin-bottom: var(--space-xlarge);
    }
  }

  .logo-mobile {
    display: none;
  }

  .tab-link-group {
    flex-direction: row;
    padding: var(--space-base) var(--space-xlarge);

    .tab-text {
      font-size: var(--font-size-xsmall);
      text-align: center;
      color: var(--color-text-base);
      margin-left: var(--space-base);
    }
  }

  .tab-link-group.active {
    background-color: #eb4a5a11;
    border-radius: var(--border-radius-button);
  }
}

.baige-wrapper,
.yellow-wrapper {
  width: 100%;
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .baige-wrapper,
  .yellow-wrapper {
    margin-left: 240px;
  }
}
