.toucan-progress-container {
  position: relative;
  width: auto;
  height: auto;
  z-index: 4;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 150px;
    height: 150px;
  }

  .progress-toucan-1 {
    opacity: 1;
    animation: fade-1 3s ease-out infinite;
  }

  .progress-toucan-2 {
    opacity: 1;
    width: 150px;
    height: 150px;
    animation: fade-2 3s ease-out infinite;
  }

  .progress-toucan-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin-top: 120px;
    opacity: 1;
    animation: percentage 1s ease-out;
  }
}

.balls-container-1 {
  rotate: 30deg;
}

.balls-container-2 {
  rotate: 45deg;
}

.ball {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: var(--color-accent);
}

.ball-1 {
  translate: 0 0;
  animation: ball-1 3s ease-out infinite;
}

.ball-2 {
  translate: 0 0;
  animation: ball-2 3s ease-out infinite;
}

.ball-3 {
  translate: 0 0;
  animation: ball-3 3s ease-out infinite;
}

.ball-4 {
  translate: 0 0;
  animation: ball-4 3s ease-out infinite;
}

@keyframes percentage {

  0% { opacity: 0; }

  100% { opacity: 1; }
}

@keyframes ball-1 {

  0% { translate: 0 0; }

  90% { translate: 0 0; }

  100% { translate: 200px 200px; }
}

@keyframes ball-2 {

  0% { translate: 0 0; }

  90% { translate: 0 0; }

  100% { translate: -200px 200px; }
}

@keyframes ball-3 {

  0% { translate: 0 0; }

  90% { translate: 0 0; }

  100% { translate: 200px -200px; }
}

@keyframes ball-4 {

  0% { translate: 0 0; }

  90% { translate: 0 0; }

  100% { translate: -200px -200px; }
}

@keyframes fade-1 {

  0% { opacity: 1; }

  75% { opacity: 0; }

  100% { opacity: 0; }
}

@keyframes fade-2 {

  0% { opacity: 0; }

  75% { opacity: 1; }

  85% {
    opacity: 1;
    width: 150px;
    height: 150px;
  }

  90% {
    opacity: 1;
    width: 200px;
    height: 200px;
  }

  95% { opacity: 0; }

  100% { opacity: 0; }
}
