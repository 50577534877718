.indicators-wrapper {
  display: flex;
  width: 100%;
}

.indicators-labels {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-xsmall);
}

.active-indicator.eq {
  border-bottom: 4px solid var(--color-text-base);
}

.active-indicator.match {
  border-bottom: 4px solid var(--color-accent);
}

.indicator {
  border-bottom: 4px solid #ddd;
}
