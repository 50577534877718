.gender-radio-btn {
  position: relative;
  display: flex;
  align-items: center;
  margin: var(--space-base) 0;

  .gender-radio-icon {
    position: absolute;
    top: 8px;
    left: 4px;
  }

  .gender-radio-label {
    margin-left: var(--space-base);
  }
}

input[type='radio'] {
  cursor: pointer;
}

input.other-input {
  padding-left: var(--space-base);
  margin-top: -4px;
}
