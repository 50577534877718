@import '../../../baseStyle/variables.scss';
@import '../../../baseStyle/placeholders.scss';

.eq-test {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--color-secondary);
  overflow-y: scroll;
  z-index: 1009;
}

.eq-test-wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
}

.eq-test-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 85%;
  text-align: left;
  margin: 0 auto;
  margin-top: 140px;
}

.eq-test-tucan {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: none;
  }
}

.loading-test {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.survey-header {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  padding: var(--space-large) 0;
  padding-top: 64px;
  background: var(--color-secondary);
  z-index: 1008;
}

.survey-header-title-group {
  display: flex;
  align-items: flex-start;

  .eq-test-back-btn {
    margin-right: var(--space-large);
  }
}

.visible-scale {
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.scale-segment {
  position: relative;
  border-right: 1px solid transparent;
}

.question-btn-wrapper {
  min-width: 100%;

  button {
    max-width: 100%;
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .eq-test-tucan {
    flex: 1;
    background: var(--color-secondary-darken);

    img {
      display: block;
      position: fixed;
      width: 50%;
      left: 0;
      padding-top: 0;
    }
  }

  .survey-header {
    width: 50%;
    padding: 0 64px;
    padding-top: 64px;
  }

  .eq-test-container {
    padding: 0 64px;
    width: 100%;
  }

  .question-btn-wrapper {
    min-width: 50%;
  }

  .loading-test {
    left: 75%;
    transform: translate(-50%, -75%);
  }
}
