.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: transparent;
  border-color: #696969;
  border-style: solid;
  border-width: 2px;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background: #696969;
  transition: 0.4s;
}

input:checked+.slider {
  background: rgba($color: #b8dee3, $alpha: 100%);
}

input:focus+.slider {
  box-shadow: 0 0 1px rgba($color: #b8dee3, $alpha: 100%);
}

input:checked+.slider::before {
  transform: translateX(17px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}
