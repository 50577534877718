@import '../../../baseStyle/variables.scss';
@import '../../../baseStyle/placeholders.scss';

.pred-res {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--color-secondary);
  overflow-y: scroll;
  z-index: 1009;
}

.pred-res-wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
}

.pred-res-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 85%;
  text-align: left;
  margin: 0 auto;
  margin-top: 80px;
}

.pred-res-tucan {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: none;
  }
}

.loading-test {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pred-res-header {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  padding: 32px 0;
  background: var(--color-primary);
  z-index: 1008;
}

.pred-res-header-title-group {
  display: flex;
  align-items: baseline;
}

.visible-scale {
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.scale-segment {
  position: relative;
  border-right: 1px solid transparent;
}

.question-btn-wrapper {
  min-width: 100%;

  button {
    max-width: 100%;
  }
}

.beta-tag {
  font-size: var(--font-size-small);
  text-transform: uppercase;
}

.white {
  color: #fff;
}

.pred-res-card-hide {
  display: none;
}

.pred-res-card-show {
  display: block;
  max-width: 400px;
  background: var(--color-secondary);
  border-radius: var(--border-radius-modal);
  padding: var(--space-xlarge);
  margin: var(--space-xlarge) auto var(--space-xlarge) auto;
  margin-top: 40px;
}

.action-icons {
  display: flex;
  justify-content: center;
  align-content: center;

  img {
    display: block;
    width: 64px;
    height: 64px;
    padding: var(--space-large);
    border: 2px solid #eee;
    border-radius: 100%;
    margin: var(--space-large);
    cursor: pointer;
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .pred-res-tucan {
    flex: 1;
    background: var(--color-secondary-darken);

    img {
      display: block;
      position: fixed;
      width: 50%;
      left: 0;
      padding-top: 0;
    }
  }

  .pred-res-header {
    padding: 32px 64px;
    width: 50%;
  }

  .pred-res-container {
    width: 100%;
    padding: 0 64px;
    margin-top: 120px;
  }

  .question-btn-wrapper {
    min-width: 50%;
  }

  .loading-test {
    left: 75%;
    transform: translate(-50%, -75%);
  }
}
