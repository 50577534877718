%flex-column-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

%signup-steps-card {
  background: var(--color-secondary);
  border-radius: var(--border-radius-container);
  box-shadow: 0 8px 16px #0001, 0 6px 6px #0001;
  padding: var(--space-large);
}

%backdrop-filter {
  position: absolute;
  backdrop-filter: blur(var(--blur-overlay));
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  z-index: -1;
}

%signup-steps-circle {
  z-index: -10;
  width: 1920px;
  height: 1280px;
  position: absolute;
  border-radius: 50%;
  background: var(--color-accent);
}

%edit-btn-style {
  width: 40px;
  height: 40px;
  background: var(--color-secondary);
  padding: 7px 7px 0 9px;
  box-shadow: 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: var(--border-radius-button);
}
