@import '../../baseStyle/variables.scss';
@import '../../baseStyle/placeholders.scss';

.expansion-link {
  display: flex;
  align-items: center;
  color: var(--color-text-base);
  margin-top: var(--space-small);
  cursor: pointer;
}

.expansion-text {
  max-height: 0;
  overflow: hidden;
  margin-top: var(--space-small);
  margin-bottom: var(--space-small);
  transition: max-height 0.25s ease;
}

.expansion-text.visible {
  max-height: 240px;
  overflow: scroll;
}

.arrow-show {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-left: 8px solid var(--color-text-base);
  border-bottom: 4px solid transparent;
  margin-right: var(--space-xsmall);
}

.arrow-hide {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid var(--color-text-base);
  margin-right: var(--space-xsmall);
}

@media only screen and (max-width: $max-mobile-small-breakpoint) {

  .expansion-link,
  .expansion-text {
    font-size: var(--font-size-xsmall);
  }
}

@media only screen and (min-width: $min-mobile-big-breakpoint) {

  .expansion-link,
  .expansion-text {
    font-size: var(--font-size-small);
  }
}
