// WIP
// Just a thought on how to combine different types of variables
@use 'sass:color';

$primary-darken-1: color.adjust(#eb4a5a, $lightness: -10%); // darken(#bb64ff, 10);
$primary-lighten-1: color.adjust(#eb4a5a, $lightness: 7.5%); // lighten(#bb64ff, 7.5);
$primary-lighten-2: color.adjust(#eb4a5a, $lightness: 10%); // lighten(#bb64ff, 10);
$error-lighten: color.adjust(#eb4a5a, $lightness: 7.5%); // lighten(#ff5252, 7.5);

// font-family: SohneBreit-Kraftig, sans-serif;
// font-family: Space Grotesk, sans-serif;

:root {
  --font-family-base: 'Space Grotesk', sans-serif;

  // Base colors
  --color-primary: #eb4a5a;
  --color-accent: #f3ca50;
  --color-secondary: #fef9ef;
  --color-badge: #3a8b75;
  --color-error: #eb4a5a;

  // Variant colors
  --color-primary-darken-1: #{$primary-darken-1};
  --color-primary-lighten-1: #{$primary-lighten-1};
  --color-primary-lighten-2: #{$primary-lighten-2};
  --color-secondary-darken: #f5efe2;
  --color-secondary-accent: #b8dee3;
  --color-error-lighten: #{$error-lighten};

  // Other
  --background-list-odd: #fefefe;
  --background-list-even: #f5f5f5;
  --background-overlay: rgb(0 0 0 / 15%);
  --blur-overlay: 8px;
  --background-base: var(--color-secondary-darken);
  --background-gray: #eee;
  --color-text-base: #303030;
  --color-text-h1: var(--color-text-base);

  // Font sizes
  --font-size-xsmall: 0.75rem; // Small text: 12px
  --font-size-small: 0.875rem; // Modal text: 14px
  --font-size-base: 1rem; // Basic body text: 16px Button text: 16px
  --font-size-large: 1.25rem; // Subheader text: 20px
  --font-size-xlarge: 1.5rem; // Header Text: 24px

  // Spacing
  --space-xsmall: 4px;
  --space-small: 8px;
  --space-base: 12px;
  --space-large: 16px;
  --space-xlarge: 24px;

  // Borders
  --border-radius-container: 6px;
  --border-radius-modal: 8px;
  --border-radius-button: 4px;
  --border-radius-input-text: 4px;
  --border-line: 1px;
  --border-line-bold: 2px;

  // Shadows
  --shadow-inset: inset 0 0 4px #ccc;
  --shadow-raise: 0 8px 8px #0000000f;

  // Heights
  --header-height: 96px;
  --bottom-nav-height-mobile: 96px;

  // Content width
  --desktop-content-width: 960px;
}

// Media Queries Breakpoints
$max-mobile-small-breakpoint: 480px;
$min-mobile-big-breakpoint: 481px;
$max-mobile-breakpoint: 1023px;
$min-desktop-breakpoint: 1024px;
