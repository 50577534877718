.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    vertical-align: sub;
  }

  input {
    padding-left: 28px;
    margin: 0;
  }

  .icon-wrapper {  // TODO: Move to global styles!!
    margin-right: var(--space-small);
  }

  .show-password-wrapper {
    position: absolute;
    right: var(--space-base);
    width: 24px;
    height: 24px;
    color: #696969;
  }
}

.floating-label-content {
  position: relative;
  display: flex;
  justify-content: center;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 12px;
  padding: 0 4px;
  background: var(--color-secondary);
  transition: 0.2s ease all;
}

.floating-input {
  background: var(--color-secondary);

  ~ .floating-label {

    svg {
      stroke: #a4a4a4;
    }
  }

  &.not-empty,
  &:focus {
    padding-left: 4px;

    ~ .floating-label {
      top: -8px;
      left: 0;
      font-size: 12px;
      color: #a4a4a4;

      .icon-wrapper {
        margin-right: var(--space-xsmall);
      }

      svg {
        vertical-align: sub;
        width: 16px;
        height: 16px;
        stroke: #a4a4a4;
      }
    }
  }

  &:focus {
    padding-left: 4px;

    ~ .floating-label {
      color: #a4a4a4;

      svg {
        stroke: #a4a4a4;
      }
    }
  }
}

.input-wrapper-error {

  .floating-input,
  .floating-input:focus {
    border: 2px solid var(--color-error);
    color: var(--color-error);

    ~ .floating-label {

      svg {
        stroke: var(--color-error);
      }
    }
  }

  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--color-error);
  }
}

.placeholder-error {
  color: var(--color-error);
}
