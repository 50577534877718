@import '../../../../baseStyle/variables.scss';

.community-tab-filters {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.community-tab-filters-wrapper {
  position: relative;
}

.filters-shade-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 34px;
  background: linear-gradient(to left, rgb(254 249 239 / 100%), rgb(254 249 239 / 0%));
}

.filter-tab {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: 48px;
}

.community-tab-filters .filter-tab:not(:last-child) {
  margin-right: var(--space-base);
}

.filter-tab button {
  background: none;
  border: none;
}

.filter-tab button.fab-button-small {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-large);
  color: var(--color-secondary);
  background: var(--color-primary);
  border-radius: 100%;
  border: 2px solid var(--color-primary-lighten-1);
  cursor: pointer;
}

.filter-tab .active {
  border-bottom: 2px solid var(--color-primary);
  color: var(--color-primary);
}

.filter-tab .capitalize {
  text-transform: capitalize;
}

.community-item-list .community-item:not(:first-child) {
  border-top: 4px solid #efefef;
}

.community-item-list,
.community-item.default-item {
  padding-bottom: 60px;
}

.fab-button {
  position: fixed;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-primary);
  border-radius: 100%;
  border: 2px solid var(--color-primary-lighten-1);
  cursor: pointer;
}

@media only screen and (max-width: $max-mobile-breakpoint) {

  .fab-button {
    right: var(--space-large);
    bottom: calc(96px + var(--space-base));
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .fab-button {
    left: 240px - 30px;
    bottom: var(--space-base);
  }
}
