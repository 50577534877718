.reset-password-msg-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: var(--color-accent);
  z-index: 999;

  .screen-icon {
    display: block;
    margin: 0 auto;
    width: 150px;
  }
}

.reset-pass-content {
  max-width: 400px;
  margin: 0 auto;
}
