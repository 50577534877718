.notifications-item {
  position: relative;
  color: var(--color-text-base);
  padding: var(--space-xlarge);
  padding-left: 24px;
  border-bottom: 4px solid #efefef;
  cursor: pointer;

  button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.notifications-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar-notifications-wrapper {
  width: 36px;
  height: 36px;
  margin-right: var(--space-base);
}

.avatar-notifications {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid #ccc; // Delete when have a real user data
}

.notifications-content,
.notifications-elapsed-time {
  font-size: var(--font-size-small);
}

.notifications-elapsed-time {
  margin-left: var(--space-base);
}

.tm-badge {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: var(--color-badge);
  margin-left: var(--space-xsmall);
}

.tm-badge-icon {
  position: absolute;
  display: inline-block;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 4px;
  top: 6px;
  height: 7px;
  width: 4px;
  border-bottom: 1px solid var(--color-secondary);
  border-right: 1px solid var(--color-secondary);
}

.notifications-text {
  margin-left: var(--space-xsmall);
}

.notifications-unread-dot {
  position: absolute;
  top: calc(50% - 3px);
  left: 16px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: var(--color-badge);
}
