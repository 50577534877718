.skills-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--space-large);
}

.skill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-base);
  font-size: var(--font-size-small);
  border: 1px solid var(--color-text-base);
  border-radius: 6px;
  padding: var(--space-small);
  margin: var(--space-small) var(--space-small) 0 0;

  .close-icon-wrapper {
    height: 22px;
    margin-left: var(--space-small);
  }

  svg {
    width: 22px;
    height: 22px;
    padding: var(--space-xsmall);
    background: #ccc;
    border-radius: 50%;
    cursor: pointer;
  }
}
