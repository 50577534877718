.score-board-container {
  background: var(--background-list-odd);
  border-radius: var(--border-radius-container);
  color: var(--color-text-base);
  text-align: left;

  .score-board-item {
    padding: var(--space-large);
  }

  .score-board-item-title {
    display: flex;
    align-items: center;
    font-weight: 700;

    .score-board-title {
      font-size: var(--font-size-small);
      margin-right: var(--space-small);
    }

    .score-board-score {
      font-size: var(--font-size-small);
      color: var(--color-primary);
    }
  }

  .score-board-text-container {
    padding-top: var(--space-small);

    .score-board-text {
      font-size: var(--font-size-xsmall);
    }
  }
}

.score-board-item-shadow {
  background: var(--background-list-even);
}
