@import '../../../../../baseStyle/variables.scss';

.edit-profile-tab-filters-wrapper {
  position: relative;
}

.edit-profile-tab-filters {
  overflow-x: hidden;
  overflow-y: hidden;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: 48px;
}

.profile-filter-tab {
  margin-right: var(--space-large);
}

.profile-filter-tab button {
  background: none;
  border: none;
  padding-bottom: var(--space-base);
  cursor: pointer;
}

.profile-filter-tab button.fab-button-small {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-large);
  color: var(--color-secondary);
  background: var(--color-primary);
  border-radius: 100%;
  border: 2px solid var(--color-primary-lighten-1);
}

.profile-filter-tab .active {
  border-bottom: 2px solid var(--color-primary);
  color: var(--color-primary);
}

.profile-tab-line {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #eaecf0;
  z-index: -1;
}
