@import '../../../baseStyle/variables.scss';
@import '../../../baseStyle/placeholders.scss';

.eq-prescreen {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-secondary);
}

.eq-prescreen-wrapper {
  display: flex;
  flex: 1;
}

.eq-prescreen-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.eq-prescreen-tucan {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    padding-top: var(--header-height);
    margin: 0 auto;
  }
}

.eq-prescreen-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-secondary);
  padding: 0 var(--space-xlarge);
}

.eq-prescreen-header-group {
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    cursor: pointer;
  }
}

.eq-prescreen-title {
  display: block;
  text-align: center;
  flex-grow: 2;
}

.content-list li {
  display: flex;
  align-items: center;
}

.list-item-dot {
  display: block;
  min-width: 22px;
  min-height: 22px;
  border-radius: 50%;
  background: #6ad69d;
  border: 2px solid #6ad69d;
  box-shadow: inset 0 0 0 2px var(--color-secondary);
  margin-right: var(--space-base);
}

.eq-prescreen-title-desktop {
  display: none;
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .eq-prescreen {
    flex-direction: row;
  }

  .eq-prescreen-header {
    position: fixed;
    top: 0;
    left: 50%;
    width: 50%;
  }

  .eq-prescreen-title {
    display: none;
  }

  .eq-prescreen-wrapper {
    padding-top: var(--header-height);
  }

  .eq-prescreen-container {
    max-width: 600px;
  }

  .eq-prescreen-tucan {
    flex: 1;
    background: var(--color-secondary-darken);

    img {
      position: fixed;
      width: 50%;
      top: 25%;
      left: 0;
      padding-top: 0;
    }
  }

  .eq-prescreen-title-desktop {
    display: block;
    text-align: center;
  }
}

.title-second-row {
  display: block;
  padding-top: var(--space-small);
}
