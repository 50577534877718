.select-box {
  width: 100%;
  background-color: transparent;
  padding: 0.75rem 0;
}

.dropdown-list {
  position: absolute;
  max-height: 240px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: var(--shadow-raise);
  border-radius: 0 0 var(--border-radius-modal) var(--border-radius-modal);
  z-index: 1008;

  li {
    padding: var(--space-small);
    padding-left: var(--space-base);
  }

  li:nth-child(odd) {
    background: var(--background-list-odd);
    border-left: 1px solid var(--background-list-odd);
    border-right: 1px solid var(--background-list-odd);
  }

  li:nth-child(even) {
    background: var(--background-list-even);
    border-left: 1px solid var(--background-list-even);
    border-right: 1px solid var(--background-list-even);
  }

  li:nth-child(odd).selected,
  li:nth-child(even).selected {
    color: var(--color-secondary);
    font-weight: 700;
    background: var(--color-primary);
    border-left: 1px solid var(--color-primary);
    border-right: 1px solid var(--color-primary);
  }
}

.dropdown-wrapper {
  position: relative;
}
