.month-year-picker-container {
  display: flex;

  input {
    margin: 0;
  }

  .date-input-wrapper {
    flex: 1 1 0;
  }

  .date-input-wrapper:not(:first-child) {
    margin-left: var(--space-small);
  }
}

.align-class-wrappper {
  display: flex;

  .align-class {
    flex: 1 1 0;
  }

  .align-class:not(:first-child) {
    margin-left: var(--space-small);
  }

  label {
    font-size: 14px;
  }
}

.btn-delete-temp { // TODO: remove when [delete] is moved to parent
  display: flex;
  justify-content: center;
  margin-top: var(--space-base);

  img {
    width: 16px;
    margin-right: var(--space-small);
  }
}
