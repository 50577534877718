.options-menu {
  position: absolute;
  top: 16px;
  right: 0;
  width: 200px;
  background: var(--color-secondary);
  border-radius: var(--border-radius-modal);
  box-shadow: var(--shadow-raise);

  &-item {
    display: flex;
    align-items: center;
    font-size: var(--font-size-small);
    height: 45px;
    padding: 0 var(--space-large);
  }

  &-item:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }

  &-icon {
    margin-right: var(--space-base);
  }
}
