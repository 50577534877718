@import '../../../../baseStyle/variables.scss';
@import '../../../../baseStyle/placeholders.scss';

.header-gradient {
  height: 120px;
  background: var(--color-badge);
  background: linear-gradient(90deg, var(--color-badge) 0%, var(--color-accent) 50%, var(--color-primary) 100%);
}

.profile-img-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--desktop-content-width);
  padding-top: var(--space-xlarge);
  margin: 0 auto;
  margin-top: -80px;
}

.profile-img {
  border-radius: 50%;
  background: var(--color-secondary);
  padding: var(--space-small);
}

#profile-img-input {
  display: none;
}

.profile-img-input-group,
.profile-btns-group {
  display: flex;
  align-items: center;
}

.edit-icon {
  @extend %edit-btn-style;

  margin-left: -16px;
}

.settings-icon {
  display: flex;
  align-items: center;
}

.notifications-icon {
  margin-right: var(--space-large);
}

.profile-page-content {
  width: 100%;
  padding-top: var(--space-xlarge);
  padding-bottom: var(--space-xlarge);
}

.profile-banners-wrapper {
  padding-top: var(--space-xlarge);
}

.profile-banners {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: var(--space-large);
  margin: 0 auto;
}

@media only screen and (max-width: $max-mobile-breakpoint) {

  .overview-form,
  .tab-content {
    padding-bottom: calc(var(--space-xlarge) + 96px);
  }

  .profile-img {
    width: 120px;
    height: 120px;
  }

  .profile-btns-group {
    margin-top: calc(3 * var(--space-xlarge));
  }

  .profile-page-content {
    padding: var(--space-xlarge);
    padding-bottom: calc(96px + var(--space-xlarge));
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .overview-form,
  .tab-content {
    padding-bottom: var(--space-xlarge);
  }

  .profile-img {
    width: 160px;
    height: 160px;
  }

  .profile-btns-group {
    margin-top: var(--space-xlarge);
  }

  .notifications-icon {
    display: none;
  }
}
