@import '../../baseStyle/variables.scss';

.message-input {
  min-height: 72px;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0 var(--space-base);
  background-color: var(--color-secondary);
  border-top: 1px solid var(--color-secondary-darken);

  textarea {
    min-width: 220px;
    background-color: var(--color-secondary);
    margin-right: var(--space-base);
    padding: var(--space-small);
  }

  textarea,
  textarea:focus,
  textarea:focus-visible {
    border: none;
  }

  ::placeholder {
    font-size: var(--font-size-small);
    color: var(--color-text-base);
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .message-input {
    width: calc(100% - 240px);
  }
}

.input-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-secondary);
  margin-bottom: var(--space-xlarge);
}

button.btn-send {
  height: 32px;
  width: 32px;
  border: none;
  background-color: var(--color-secondary);
  padding: 0;
  margin: 0;
  cursor: pointer;
}

button.btn-file {
  display: flex;
  align-items: center;
  height: 32px;
  width: auto;
  color: var(--color-text-base);
  border: none;
  background-color: var(--color-secondary);
  padding: 0 var(--space-base);
  cursor: pointer;

  label {
    margin-left: var(--space-small);
    font-size: var(--font-size-small);
    cursor: pointer;
  }
}
