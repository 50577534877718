@import '../../baseStyle/variables.scss';
@import '../../baseStyle/placeholders.scss';

.backdrop-filter {
  @extend %backdrop-filter;
}

.job-list.grid {
  display: grid;
  grid-gap: var(--space-base);
  width: 100%;
  height: auto;
}

.job-list::-webkit-scrollbar {
  width: 0;
}

@media only screen and (max-width: $max-mobile-small-breakpoint) {

  .job-list.grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: $min-mobile-big-breakpoint) {

  .job-list.grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .job-list.grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
