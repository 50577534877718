.arrow-down,
.arrow-up {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.arrow-up {
  border-bottom: 6px solid #ccc;
}

.arrow-down {
  border-top: 6px solid #ccc;
}

.chevron-container {
  padding-right: var(--space-small);
}

.country-dropdown {
  position: relative;

  li {
    cursor: pointer;
  }
}

.country-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--color-secondary);
  border: none;
  border-bottom: 2px solid #a4a4a4; // WIP color
  text-align: left;

  &:focus {

    .arrow-up {
      border-bottom: 6px solid var(--color-primary);
    }

    .arrow-down {
      border-top: 6px solid var(--color-primary);
    }
  }

  .selected-location {
    display: inline-block;
    padding-right: var(--space-small);
  }
}

.countries-list {
  position: absolute;
  max-height: 240px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: var(--shadow-raise);
  border-radius: 0 0 var(--border-radius-modal) var(--border-radius-modal);
  z-index: 1008;

  li {
    padding: var(--space-small);
    padding-left: var(--space-base);
  }

  li:nth-child(odd) {
    background: var(--background-list-odd);
    border-left: 1px solid var(--background-list-odd);
    border-right: 1px solid var(--background-list-odd);
  }

  li:nth-child(even) {
    background: var(--background-list-even);
    border-left: 1px solid var(--background-list-even);
    border-right: 1px solid var(--background-list-even);
  }

  li:nth-child(odd).selected,
  li:nth-child(even).selected {
    color: var(--color-secondary);
    font-weight: 700;
    background: var(--color-primary);
    border-left: 1px solid var(--color-primary);
    border-right: 1px solid var(--color-primary);

    svg {
      fill: var(--color-secondary);
    }
  }

  .item-icon {
    vertical-align: baseline;
    margin-right: var(--space-small);
  }
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  border-bottom: 2px solid #a4a4a4; // WIP color

  input {
    border-radius: var(--border-radius-modal); // Needed so container border is not overlapped by input box
    flex-grow: 1;
    border: none;
    height: 48px;
  }

  input:focus,
  input:focus-visible {
    outline: none;
  }
}

.input-container:focus-within {
  border-color: var(--color-primary);
}
