@import '../../../baseStyle/variables.scss';
@import '../../../baseStyle/placeholders.scss';

.res-job-title {
  margin-top: 64px;
}

.filters-wrapper {
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }
}
