.data-picker {
  display: flex;
  align-items: flex-start;
  width: 100%;
  border: none;
  border-bottom: 2px solid #a4a4a4;
  padding-left: 0;
}

.data-picker-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-picker-text {
  margin-left: var(--space-base);

  p {
    margin: 0;
    padding: 0;
  }
}
