.report-radio-btn {
  position: relative;
  display: flex;
  align-items: center;
  margin: var(--space-small);

  .report-radio-icon {
    position: absolute;
    top: 8px;
    left: 4px;
  }

  .report-radio-label {
    margin-left: var(--space-base);
  }
}
