.back-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  backdrop-filter: blur(var(--blur-overlay));
  background: var(--color-secondary);
}

.community-item {

  .community-item-content {
    white-space: pre-wrap;
  }
}

.community-item-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.community-item-link {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.community-avatar-group {
  display: flex;
  align-items: center;
}

.dots-icon {
  margin: var(--space-xsmall) 0;
  cursor: pointer;
}

.options-menu-wrapper {
  position: relative;
}

.num-likes {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: #9c9c9c;
    margin-left: var(--space-small);
  }
}

.user-name-community {
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  font-weight: 700;
}

.tm-badge {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: var(--color-badge);
  margin-left: var(--space-xsmall);
}

.tm-badge-icon {
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 4px;
  top: 6px;
  height: 7px;
  width: 4px;
  border-bottom: 1px solid var(--color-secondary);
  border-right: 1px solid var(--color-secondary);
}

.user-title-community {
  font-size: var(--font-size-xsmall);
  text-transform: capitalize;
}

.avatar-community-wrapper {
  width: 28px;
  height: 28px;
  margin-right: var(--space-base);
}

.avatar-community {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 1px solid var(--color-primary);
}

.community-item-time {
  font-size: 10px;
  color: #9c9c9c;
  padding: 0 var(--space-large);
}

.community-item-footer {
  display: flex;
  border-top: 1px solid var(--color-secondary-darken);
  border-bottom: 3px solid var(--color-secondary-darken);
  padding: var(--space-large) 0;
}
