.career-prediction-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: var(--color-secondary);
}

.career-prediction-loading-text {
  font-size: 14px; // Should be revisited after global font-size variables are created!
  text-align: center;
  width: 160px;
  animation: fade-in 1s ease-in;
}

@keyframes fade-in {

  0% { opacity: 0.1; }

  100% { opacity: 1; }
}
