.banner-container {
  text-align: center;
  padding: var(--space-xlarge);
  border: 1px solid #ccc;
  border-radius: var(--border-radius-container);

  .banner-icon {
    padding: var(--space-small);
    border: 1px solid #ccc;
    border-radius: var(--border-radius-container);
  }
}

.career-showcase {
  text-align: left;
}
