@import '../../../../../../baseStyle/variables.scss';

.overview-form {

  .settings-card-container {
    margin-top: var(--space-xlarge);
  }

  .data-picker {
    color: #a4a4a4;
    padding: var(--space-base) 0;
  }
}

.card-input {
  padding: 0 var(--space-large);

  .input-wrapper {

    input {
      background: #fffcf6;
    }

    .floating-label {
      background: #fffcf6;
    }
  }
}

.card-input:first-child {
  padding-top: var(--space-large);
}

.card-input:last-child {
  padding-bottom: var(--space-large);
}

.card-header-group {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  background: #b8dee3;
  border-radius: var(--border-radius-container) var(--border-radius-container) 0 0;

  button {
    min-width: 64px;
    margin-right: 6px;
  }
}
