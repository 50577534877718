.admin-tabs {
  display: flex;
  gap: 1rem;
}

.admin-list td {
  background-color: lime;
  white-space: nowrap;
  overflow: hidden;
}

.admin-list {
  border-collapse: separate;
  border-spacing: 0.5rem;
}
