.inital-loading-container {
  height: 100vh;
  width: 100vw;
  background: var(--color-secondary);
  background-blend-mode: overlay;
  background-size: cover;
}

.loader-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
