.vertical-horizonal-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}

.error-page-background {
  position: fixed;
  background: var(--color-accent);
  z-index: -1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  border-top-left-radius: 0;
}

.error-page-image {
  display: block;
  width: 100px;
  margin: var(--space-xlarge) auto;
}

.error-page-heading {
  text-align: center;
  color: #fff;
  font-size: var(--font-size-xlarge);
}

.error-page-text {
  text-align: center;
  color: #fff;
}

.error-page-nav-button {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 50px;
  width: 80%;
}
