.autocomplete-container {
  position: relative;
  margin: var(--space-base) 0;

  input {
    margin: 0;
    text-transform: capitalize;
  }
}

.results-container {
  position: absolute;
  top: 50px;
  max-height: 168px;
  width: 100%;
  overflow-y: scroll;
  border-radius: 16px;
  box-shadow: var(--shadow-raise);
  z-index: 1009;

  div {
    display: flex;
    justify-content: center;
    background: var(--background-list-odd);
    padding: var(--space-small) var(--space-base);
  }

  p {
    font-size: var(--font-size-small);
    padding: var(--space-small) var(--space-base);
    text-transform: capitalize;
  }

  p:nth-child(odd) {
    background: var(--background-list-odd);
  }

  p:nth-child(even) {
    background: var(--background-list-even);
  }
}
