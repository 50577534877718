@import '../../../../baseStyle/variables.scss';

.community-item-overview {
  overflow-y: scroll;
  width: 100%;
  padding-top: 96px;
}

.community-item-overview,
.comment-item {

  .community-item-header {
    padding: 0 var(--space-large);
  }

  .community-item-content {
    white-space: pre-wrap;
    padding: 0 var(--space-large);
  }

  .community-item-footer {
    padding: var(--space-large);
  }
}

.community-item-comments {

  .comment-item {
    border-top: 1px solid var(--color-secondary-darken);
  }

  .comment-content {
    white-space: pre-wrap;
    padding: 0 var(--space-large);
  }
}

.num-comments {
  display: flex;
  align-items: center;
  margin-right: var(--space-xlarge);

  .comments-link {
    display: flex;
    align-items: center;
  }

  span {
    color: #9c9c9c;
    margin-left: var(--space-small);
  }

  .num-comments-primary {
    color: var(--color-primary);
  }
}

@media only screen and (max-width: $max-mobile-breakpoint) {

  .community-item-overview {
    height: calc(100vh - 99.5px);
  }
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .community-item-overview {
    height: calc(100vh - 96px - 99.5px);
  }
}
