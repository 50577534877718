.startup-fit-results-text {
  padding: var(--space-large);
  color: var(--color-text-base);
  background: var(--background-list-odd);
  border-radius: var(--border-radius-container);
}

.startup-fit-meter-wrapper {
  display: flex;
  justify-content: center;
}
