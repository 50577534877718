.question-container {
  background: var(--color-secondary-darken);
  border-radius: var(--border-radius-container);
  margin-bottom: var(--space-large);

  .question-title-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--space-large);

    .question-number {
      font-weight: 700;
      font-size: var(--font-size-base);
    }

    .question-wrapper {
      font-size: var(--font-size-base);
      margin-left: var(--space-small);
    }
  }
}

.bottom-question-card {
  background: rgb(249 249 249 / 80%);
  border-radius: 0 0 var(--border-radius-container) var(--border-radius-container);
  padding-bottom: var(--space-large);
}

.question-form-likert-scale-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-large);
}

.form-question-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--space-large);

  p {
    font-size: var(--font-size-small);
  }
}
