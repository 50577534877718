.toast-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 315px;
  display: flex;
  flex-direction: column;
  animation-name: pop-in;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  z-index: 1010;
}

@keyframes pop-in {

  from { bottom: -120px; }

  25% { bottom: -120px; }

  50% { bottom: 60px; }

  to { bottom: 20px; }
}

.toast-message-container {
  display: inline-flex;
  align-items: center;
  padding: 0.3rem;
  border-radius: var(--border-radius-container);
  min-height: 75px;
  margin-top: 0.5rem;

  p {
    font-size: 12px;
    width: 100%;
  }

  svg {
    align-self: center;
    margin: 0 12px;
  }

  svg:not(.close-toast-wrapper svg) {
    width: 32px;
    height: 32px;
  }
}

// Classes for white background

.error-type {
  background: #ffe6e6;
  border: 1px solid rgb(255 126 126 / 70%);
}

.success-type {
  background: #e2ffdb;
  border: 1px solid rgb(119 212 95 / 70%);
}

.warning-type {
  background: #ffead2;
  border: 1px solid rgb(255 219 126 / 70%);
}

.info-type {
  background: #f9f9f9;
  border: 1px solid rgb(156 156 156 / 70%);
}

.error-type,
.success-type,
.warning-type,
.info-type {

  p {
    color: #636363;
  }
}

// Classes for dark background

.error-type-dark {
  background: #f57777;
  border: 1px solid rgb(255 201 201 / 70%);
}

.success-type-dark {
  background: #7dcb68;
  border: 1px solid rgb(221 255 212 / 70%);
}

.warning-type-dark {
  background: #f5a375;
  border: 1px solid rgb(255 248 182 / 70%);
}

.info-type-dark {
  background: #8d7df3;
  border: 1px solid rgb(255 255 255 / 70%);
}

.error-type-dark,
.success-type-dark,
.warning-type-dark,
.info-type-dark {

  p {
    color: var(--color-secondary);
  }
}
