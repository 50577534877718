@import '../../../baseStyle/variables.scss';
@import '../../../baseStyle/placeholders.scss';

.eq-result {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-secondary);
}

.eq-result-wrapper {
  display: flex;
  flex: 1;
}

.eq-result-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.eq-result-tucan {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    padding-top: var(--header-height);
    margin: 0 auto;
  }
}

.eq-results-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-secondary);
  padding: 0 var(--space-xlarge);
}

.eq-results-header-group {
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
}

.eq-results-title {
  display: block;
  margin-left: var(--space-large);
}

.eq-result-share-btn {
  cursor: pointer;
}

.result-section {
  margin: 48px 0;
}

.results-heading {
  margin-bottom: var(--space-xlarge);
}

.result-title-group {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  h2,
  h3 {
    color: var(--color-primary);
  }
}

.score-number {
  color: var(--color-primary);
  margin-left: var(--space-small);
}

.eq-article {
  border-top: 2px solid var(--color-secondary-darken);
  padding-top: var(--space-xlarge);
  padding-bottom: var(--space-xlarge);
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .eq-result {
    flex-direction: row;
  }

  .eq-results-header {
    position: fixed;
    top: 0;
    left: 50%;
    width: 50%;
  }

  .eq-results-title {
    display: none;
  }

  .eq-result-wrapper {
    padding-top: var(--header-height);
  }

  .eq-result-container {
    max-width: 600px;
  }

  .eq-result-tucan {
    flex: 1;
    background: var(--color-secondary-darken);

    img {
      position: fixed;
      width: 50%;
      top: 25%;
      left: 0;
      padding-top: 0;
    }
  }
}
