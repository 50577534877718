@import '../../../../baseStyle/variables.scss';

.no-jobs-toucan {
  display: block;
  padding-top: 30%;
  margin-left: auto;
  margin-right: auto;
  min-width: 100px;
  max-width: 200px;
  width: 40%;
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .no-jobs-toucan {
    padding-top: 70px;
  }
}
