@import '../../../baseStyle/variables.scss';

.bottom-container {
  position: fixed;
  bottom: 0;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0 5%;
}

@media only screen and (min-width: $min-desktop-breakpoint) {

  .bottom-container {
    width: calc(100% - 240px);
  }
}
