.likert {
  display: block;
  list-style: none;
  width: 100%;
}

.likert li {
  display: inline-block;
  text-align: center;
  vertical-align: top;
}

.likert li::before {
  position: relative;
  display: block;
  top: 10px;
  left: 50%;
  content: '';
  height: 4px;
  background: #ddd;
  border-radius: 2px;
}

.likert li:last-child::before {
  background: transparent;
}

.likert li input[type='radio'] {
  position: relative;
  display: block;
  left: 50%;
  margin-left: -6px;
}

.likert li .label {
  width: 100%;
  color: var(--color-text-base);
  font-size: 10px;
}

input[type='radio'].likert-radio {
  width: 16px;
  height: 16px;
  margin-bottom: 14px;
}

input[type='radio'].likert-radio:checked {
  width: 24px;
  height: 24px;
  border: none;
  margin-top: -2px;
  margin-left: -10px;
  margin-bottom: 8px;
}
